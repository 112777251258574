<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <div class="text-center">
                            <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('externalUserIrrigation.smart_card_application_form') }}</h5>
                        </div>
                    </template>
                    <template v-slot:body>
                        <profile-status-msg :preConditionLoader="preConditionLoader" :profileStatus="profileStatus" :profile-url="'/irrigation/my-profile'" :inForm="true"/>
                        <b-overlay :show="load">
                            <b-col sm="12" @click="displayProfileCheckAlert">
                                <form-input :key="reloadComponent" ref="form" :data="formInputData" @change="setData" @submit="register">
                                    <template v-slot:submitButton>
                                        <b-button @click="smartCardApplication.final_pay = 1" type="submit" variant="primary">{{ saveBtnName}}</b-button>
                                        <b-button @click="smartCardApplication.final_pay = 0" v-if="!smrt_id" type="submit" class="ml-2" variant="warning">{{$t('externalUserIrrigation.save_draft')}}</b-button>
                                    </template>
                                </form-input>
                            </b-col>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerInfo, smartCardApplicationStore, smartCardApplicationUpdate, getSmartCardPayment } from '../../api/routes'
import FormInput from '@/components/structure/form/validationForm/FormInput'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import { Common, Repository, Address } from '@/mixins/helper-functions'
import customObject from '../../../../../store/custom'

export default {
    name: 'FormLayout',
    mixins: [Common, Address],
    components: {
        FormInput,
        ProfileStatusMsg
    },
    created () {
        this.checkUser()
        this.loadData()
        // this.tmp = JSON.parse(JSON.stringify(this.smartCardApplication))
    },
    data () {
        return {
            statePrefix: 'ExternalUserIrrigation.commonObj', // Required to get store sates for addresses
            amountLoading: false,
            profileStatus: 0,
            preConditionLoader: false,
            load: false,
            status: null,
            formData: {},
            smartCardApplication: {
                final_pay: 1,
                org_id: '',
                far_division_id: '',
                far_district_id: '',
                far_upazilla_id: '',
                far_union_id: '',
                marital_status: '',
                amount: '',
                master_payment_id: '',
                total_land: 0,
                owned_land: 0,
                lease_land: 0,
                barga_land: 0,
                attachment: []
                // same_as_personal_address: false
            },
            isMarried: false,
            smrt_id: '',
            reloadComponent: 0,
            address: {
                upazillas: [],
                districts: [],
                unions: []
            },
            addressHolder: {
                far_division_id: '',
                far_district_id: '',
                far_upazilla_id: '',
                far_union_id: ''
            },
            tmp: {},
            officeList: []
        }
    },
    computed: {
        divisionList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
        },
        saveBtnName () {
            return (this.smrt_id) ? this.$t('globalTrans.update') : this.$t('externalUserIrrigation.save_and_pay')
        },
        getData () {
            return this.smartCardApplication
        },
        getSmartCardApplicationData () {
            return this.$store.state.ExternalUserIrrigation.smartCardApplicationLists.find(item => item.id === this.smrt_id)
        },
        getFarmerDetails () {
            return this.$store.state.ExternalUserIrrigation.farmerBasicInfo
        },
        orgList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
        },
        // getParent () {
        //     Object.assign(this.smartCardApplication, this.getFarmerDetails)
        //     return this.smartCardApplication.same_as_personal_address ? this.smartCardApplication : this.addressHolder
        // },
        formInputData () {
            return {
                chunk: true,
                dataParent: this.getData,
                data: [
                    {
                        head: {
                            title: 'globalTrans.basic_information'
                        },
                        items: [
                            {
                                name: 'attachment',
                                type: 'ImageFile',
                                link: this.smartCardApplication.attachment,
                                readOnly: true,
                                label: 'globalTrans.profileImage',
                                uploadLabel: 'Upload Image',
                                caption: `
                                        <span>Only</span>
                                        <b-link href="javascript:void();">.jpg</b-link>
                                        <b-link href="javascript:void();">.png</b-link>
                                        <b-link href="javascript:void();">.jpeg</b-link>
                                        <span>allowed</span>`
                            },
                            {},
                            {
                                name: 'org_id',
                                type: 'Select',
                                label: 'org_pro.organization',
                                options: this.orgList
                            },
                            {
                                name: 'email',
                                type: 'Input',
                                label: 'globalUserData.email',
                                rules: 'nullable'
                            },
                            {
                                name: 'mobile_no',
                                type: 'Input',
                                inputType: 'number',
                                label: 'globalUserData.mobile_no'
                            }
                        ]
                    },
                    {
                        head: {
                            title: 'globalTrans.personal_information'
                        },
                        items: [
                            {
                                name: 'name',
                                type: 'Input',
                                label: 'globalTrans.name_en',
                                readOnly: true
                            },
                            {
                                name: 'name_bn',
                                type: 'Input',
                                label: 'globalUserData.name_bn',
                                readOnly: true
                            },
                            {
                                name: 'father_name',
                                type: 'Input',
                                label: 'externalUserIrrigation.father_namef',
                                readOnly: true
                            },
                            {
                                name: 'father_name_bn',
                                type: 'Input',
                                label: 'externalUserIrrigation.father_name_bn',
                                readOnly: true
                            },
                            {
                                name: 'mother_name',
                                type: 'Input',
                                label: 'externalUserIrrigation.mother_namef',
                                readOnly: true
                            },
                            {
                                name: 'mother_name_bn',
                                type: 'Input',
                                label: 'externalUserIrrigation.mother_name_bn',
                                readOnly: true
                            },
                            {
                                name: 'date_of_birth',
                                type: 'Datepicker',
                                label: 'externalUserIrrigation.date_of_birth'
                            },
                            {
                                name: 'gender',
                                type: 'Select',
                                label: 'globalUserData.gender',
                                options: customObject.genderList
                            },
                            {
                                name: 'marital_status',
                                type: 'Select',
                                label: 'globalUserData.marital_status',
                                options: customObject.maritalStatus
                            },
                            {
                                name: 'spouse_name',
                                type: 'Input',
                                label: 'globalUserData.spouse_name',
                                rules: 'nullable',
                                onIf: this.isMarried
                            },
                            {
                                name: 'spouse_name_bn',
                                type: 'Input',
                                label: 'globalUserData.spouse_name_bn',
                                rules: 'nullable',
                                onIf: this.isMarried
                            },
                            {
                                name: 'no_of_child',
                                type: 'Input',
                                inputType: 'number',
                                label: 'globalUserData.no_of_child',
                                rules: 'nullable',
                                onIf: this.isMarried
                            }
                        ]
                    },
                    {
                        head: {
                            title: 'globalTrans.applicant_address'
                        },
                        items: [
                            // {
                            //     name: 'same_as_personal_address',
                            //     type: 'Checkbox',
                            //     label: 'globalTrans.sameAsProfileAddress',
                            //     rules: 'nullable',
                            //     labelCol: 4
                            // },
                            // {},
                            {
                                name: 'far_division_id',
                                type: 'Select',
                                label: 'org_pro_division.division',
                                rules: 'required|min_value:1',
                                options: this.divisionList
                            },
                            {
                                name: 'far_district_id',
                                type: 'Select',
                                label: 'org_pro_district.district',
                                options: this.districtList
                            },
                            {
                                name: 'far_upazilla_id',
                                type: 'Select',
                                label: 'org_pro_upazilla.upazilla',
                                options: this.upazilaList
                            },
                            {
                                name: 'far_union_id',
                                type: 'Select',
                                label: 'org_pro_union.union',
                                options: this.unionList
                            },
                            {
                                name: 'office_id',
                                type: 'Select',
                                label: 'warehouseFarmer.office',
                                options: this.officeList
                            },
                            {},
                            {
                                name: 'ward_no',
                                type: 'Input',
                                inputType: 'number',
                                label: 'globalTrans.ward_no'
                            },
                            {
                                name: 'nid',
                                type: 'Input',
                                inputType: 'number',
                                label: 'externalUserIrrigation.nid',
                                readOnly: true
                            },
                            {
                                name: 'far_village',
                                type: 'Input',
                                label: 'farmerOperator.village_en',
                                readOnly: true
                            },
                            {
                                name: 'far_village_bn',
                                type: 'Input',
                                label: 'farmerOperator.village_bn',
                                readOnly: true
                            }
                        ]
                    },
                    {
                        head: {
                            title: 'externalUserIrrigation.quantity_of_land_sotok',
                            lg: 6,
                            sm: 12
                        },
                        items: [
                            {
                                name: 'owned_land',
                                type: 'Input',
                                inputType: 'number',
                                label: 'farmerOperator.owned_land'
                            },
                            {
                                name: 'lease_land',
                                type: 'Input',
                                inputType: 'number',
                                label: 'farmerOperator.lease_land'
                            },
                            {
                                name: 'barga_land',
                                type: 'Input',
                                inputType: 'number',
                                label: 'farmerOperator.barga_land'
                            },
                            {
                                name: 'total_land',
                                type: 'Input',
                                inputType: 'number',
                                label: 'farmerOperator.total_land',
                                readOnly: true
                            }
                        ]
                    },
                    {
                        head: {
                            title: 'globalTrans.other_information',
                            lg: 6,
                            sm: 12
                        },
                        items: [
                            {
                                name: 'qualification',
                                type: 'Input',
                                label: 'globalUserData.qualification',
                                rules: 'nullable'
                            },
                            {
                                name: 'training_info',
                                type: 'Input',
                                label: 'farmerOperator.training_info',
                                rules: 'nullable'
                            },
                            {
                                name: 'crop_plan',
                                type: 'Input',
                                label: 'farmerOperator.crop_plan_en',
                                rules: 'nullable'
                            },
                            {
                                name: 'crop_plan_bn',
                                type: 'Input',
                                label: 'farmerOperator.crop_plan_bn',
                                rules: 'nullable'
                            },
                            {
                                name: 'earnings',
                                type: 'Input',
                                inputType: 'number',
                                label: 'externalUserIrrigation.EarningsWithoutAgriculture',
                                rules: 'nullable'
                            },
                            {
                                name: 'amount',
                                type: 'Input',
                                label: 'externalUserIrrigation.formFee',
                                rules: 'required',
                                placeholder: this.amountLoading ? 'Loading...' : '',
                                readOnly: true
                            }
                        ]
                    }
                ]
            }
        }
    },
    watch: {
        'smartCardApplication.owned_land': function (newVal, oldVal) {
           if (newVal !== oldVal) {
               this.setbargaLand()
            }
        },
        'smartCardApplication.lease_land': function (newVal, oldVal) {
            if (newVal !== oldVal) {
               this.setbargaLand()
            }
        },
        'smartCardApplication.barga_land': function (newVal, oldVal) {
            if (newVal !== oldVal) {
               this.setbargaLand()
            }
        },
        'smartCardApplication.far_division_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtList = this.getDistrictList(newVal)
                // if (this.smartCardApplication.same_as_personal_address) {
                //     this.upazilaList = this.getUpazilaList(this.smartCardApplication.far_district_id)
                //     this.unionList = this.getUnionList(this.smartCardApplication.far_upazilla_id)
                // }
            }
        },
        'smartCardApplication.far_district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.upazilaList = this.getUpazilaList(newVal)
                // if (this.smartCardApplication.same_as_personal_address) {
                //     this.upazilaList = this.getUpazilaList(this.smartCardApplication.far_district_id)
                //     this.unionList = this.getUnionList(this.smartCardApplication.far_upazilla_id)
                // }
            }
        },
        'smartCardApplication.far_upazilla_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unionList = this.getUnionList(newVal)
                this.officeList = this.getOfficeList(newVal)
            }
        },
        'smartCardApplication.org_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.amountLoading = true
                const obj = this.getAmount(newVal, 1)
                obj.then((res) => {
                    this.smartCardApplication.amount = res.amount
                    this.smartCardApplication.master_payment_id = res.paymentId
                    this.amountLoading = false
                })
            }
        },
        'smartCardApplication.marital_status': function (newVal, oldVal) {
            this.isMarried = (newVal !== oldVal) && (newVal === 1)
        }
        // 'smartCardApplication.same_as_personal_address': function (newVal, oldVal) {
        //     if (newVal !== oldVal) {
        //         this.smartCardApplication.far_division_id = this.addressHolder.far_division_id
        //         this.smartCardApplication.far_district_id = this.addressHolder.far_district_id
        //         this.smartCardApplication.far_upazilla_id = this.addressHolder.far_upazilla_id
        //         this.smartCardApplication.far_union_id = this.addressHolder.far_union_id
        //     }
        // }
    },
    methods: {
        checkUser () {
            this.preConditionLoader = true
            RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
                if (response.success) {
                    this.profileStatus = response.data.status === 2 ? 4 : 0
                }
                this.preConditionLoader = false
            })
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            this.smartCardApplication = Object.assign(this.smartCardApplication, this.getFarmerDetails)
            const id = this.$route.params.id === undefined ? null : this.$route.params.id
            if (id) {
                this.smrt_id = parseInt(id)
                const tmp = this.getSmartCardApplicationData
                const reissueStatus = this.$store.state.ExternalUserIrrigation.reissueData
                Object.freeze(tmp)
                Object.assign(this.smartCardApplication, tmp, { reissue_status: reissueStatus })

                this.status = this.smartCardApplication.status
                const allowedStatus = [
                    customObject.smartCardStatus.find(item => item.name === 'Pending').id,
                    customObject.smartCardStatus.find(item => item.name === 'Reviewed').id
                ]
                if (!allowedStatus.includes(this.smartCardApplication.status)) {
                    return this.$router.push('/irrigation/smart-card-application')
                }
                Object.assign(this.addressHolder, this.smartCardApplication)
            }
        },
        setbargaLand () {
            this.amountLoading = true
            this.smartCardApplication.total_land = parseFloat(this.smartCardApplication.owned_land) + parseFloat(this.smartCardApplication.lease_land) + parseFloat(this.smartCardApplication.barga_land)
            this.amountLoading = false
        },
        setData (data) {
            this.smartCardApplication = Object.assign(this.smartCardApplication, data)
        },
        async register () {
            if (this.profileStatus === 0) {
                this.displayProfileCheckAlert()
                return
            }
            this.load = true
            const inReview = this.status === customObject.smartCardStatus.find(item => item.name === 'Reviewed').id
            const result = await Repository.create({
                data: this.smartCardApplication,
                baseURL: irriSchemeServiceBaseUrl,
                storeAPI: smartCardApplicationStore,
                updateAPI: inReview ? smartCardApplicationStore : smartCardApplicationUpdate,
                itemId: inReview ? false : this.smrt_id
            })
            this.load = false
            if (result.success) {
                // push notification
                // const notification = result.notification
                // this.$socket.emit('send-notification', notification)

                setTimeout(async () => {
                    this.$router.push('/irrigation/smart-card-application')
                }, 1000)
            } else {
                this.$refs.form.handleErorr(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
        },
        async getAmount (orgId, payType) {
            const param = { org_id: orgId, payment_type_id: payType }
            const response = await RestApi.getData(irriSchemeServiceBaseUrl, getSmartCardPayment, param)
            if (response.success === true) {
                return {
                    amount: response.data.amount,
                    paymentId: response.data.id
                }
            } else {
                return {}
            }
        },
        getOfficeList (upazilaId = null) {
            const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(office => office.district_id === this.smartCardApplication.far_district_id && office.status === 0)
            if (upazilaId) {
                const tmpOfficeList = officeList.filter(item => item.upazilla_id === upazilaId && item.status === 0)
                if (tmpOfficeList.length > 0) {
                    return tmpOfficeList
                }
            }
            return officeList
        },
        displayProfileCheckAlert () {
          if (this.profileStatus !== 0) {
              return
          }
          // const msg = this.$t('externalPanel.profile_complete_msg_part1') + '<a href="/irrigation/my-profile">links</a> ' + this.$t('externalPanel.profile_complete_msg_part2')
          this.$swal({
              title: this.$t('externalPanel.profile_complete_msg'),
              showCloseButton: true,
              confirmButtonText: 'Close',
              // showCancelButton: true,
              // html: msg
              focusConfirm: false
          })
        }
    }
}
</script>
